<template>
  <Dialog
    :open="true"
    @close="props.onClose"
  >
    <div
      class="fixed inset-0 flex items-center justify-center p-4 z-20 bg-zinc-700/90"
    >
      <DialogPanel
        class="border border-white w-[800px] min-h-[550px] max-h-[90vh] flex flex-col bg-black"
      >
        <canvas
          id="modal_canvas"
          ref="modal_canvas"
          class="absolute z-0 w-[800px] h-full"
        />

        <DialogTitle class="border-b border-white p-3 text-yellow-300">
          {{ text('ui.inn_logs_title') }}
        </DialogTitle>

        <div
          id="battle_log_body"
          class="relative w-full p-6 overflow-y-none pointer-events-none"
        >
          <div
            id="battle_log_labels"
            class="w-full flex text-sm font-bold"
          >
            <div class="w-[35%] text-center">{{ text('ui.description') }}</div>
            <div class="w-[20%] text-center">{{ text('ui.result') }}</div>
            <!-- upgrade/downgrade/maintain -->
            <div class="w-[45%] text-center">{{ text('ui.rewards') }}</div>
          </div>
          <div class="w-full">
            <div
              v-if="state.sorted_inn_log_entries.length === 0"
              class="mt-48 w-full text-center text-neutral-500 italic text-xs"
            >
              {{ text('ui.no_log_entries') }}
            </div>
            <div
              v-for="(log_entry, i) of state.sorted_inn_log_entries"
              :id="`inn_log_entry_${log_entry._id}`"
              :key="log_entry._id"
              class="w-full flex text-xs p-4 items-center max-h-[64px]"
            >
              <div
                class="w-[35%] text-center"
                style="font-size: 10px"
              >
                {{ text('ui.get_inn_log_entry_description')(log_entry) }}
              </div>
              <div class="w-[20%] text-center">
                <!-- {{ text(`ui.game_modes.${log_entry.game_mode}`) }}  -->
                <div
                  v-if="log_entry.new_tier > log_entry.old_tier"
                  class="text-green-500"
                >
                  Tier up!
                </div>
                <div
                  v-else-if="log_entry.new_tier < log_entry.old_tier"
                  class="text-red-500"
                >
                  Tier down :(
                </div>
                <div
                  v-else
                  class="text-yellow-500"
                >
                  Tier maintained ~
                </div>
              </div>
              <div class="w-[35%] text-center">
                <BattleRewardsIcons
                  v-if="log_entry.rewards && state.modal_pixi_app"
                  :key="`${state.rewards_key}_${i}`"
                  :inn_log_id="log_entry._id"
                  :modal_pixi_stage="state.modal_pixi_app.stage"
                  :rewards="log_entry.rewards"
                  class="mr-3"
                />
              </div>
              <div
                :class="{
                  'w-[5%] p-3 flex justify-center items-center': true,
                  invisible: claimAllBtnInvisibility(log_entry),
                }"
                style="font-size: 9px"
              >
                <div
                  class="text-center text-blue-500 hover:text-yellow-300 cursor-pointer pointer-events-auto"
                  @click="onClaimAllClick(log_entry._id, $event)"
                >
                  {{ text('ui.claim_all') }}
                </div>
              </div>
              <div class="w-[5%] flex justify-center items-center">
                <div
                  :class="{
                    ['ml-6 p-3 text-lg font-bold text-red-800 hover:text-yellow-300 cursor-pointer']: true,
                    ['pointer-events-auto']:
                      !state.pending_claim_all[log_entry._id],
                    ['pointer-events-none']:
                      state.pending_claim_all[log_entry._id],
                  }"
                  @click="deleteLogEntry(log_entry._id)"
                >
                  <DTAnimatedElipsis
                    v-if="state.pending_claim_all[log_entry._id]"
                    class="text-tiny font-extrabold"
                  />
                  <span v-else>{{ 'x' }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogPanel>
    </div>
  </Dialog>
</template>

<script setup>
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/vue';
import {
  nextTick,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  watch,
} from 'vue';
import { BattleDebriefActions } from '~/flux/actions';
import {
  CurrencyStore,
  GameStateStore,
  InnStore,
  NotificationsStore,
  PlayerStore,
} from '~/flux/stores';
import text from '~/text';
import CanvasTools from '~/view/CanvasTools';
import { DTAnimatedElipsis } from '~/view/components/common/DOM';
import BattleRewardsIcons from './BattleRewardsIcons.vue';

const props = defineProps({
  onClose: {
    type: Function,
    required: true,
  },
});

const modal_canvas = ref(null);

watch(modal_canvas, (val) => {
  if (val && !state.modal_pixi_app) {
    state.modal_pixi_app = CanvasTools.makePixiRenderer({
      canvas_id: 'modal_canvas',
      canvas_ele: val,
    });
    updateModalStagePivot();
  }
});

const state = reactive({
  modal_pixi_app: null,
  rewards_key: 0,
  sorted_inn_log_entries: [],
  pending_claim_all: {},
});

onMounted(() => {
  CurrencyStore.on(CurrencyStore.GOLD_REWARD_CLAIMED, onInnLogs);
  CurrencyStore.on(CurrencyStore.PD_REWARD_CLAIMED, onInnLogs);
  GameStateStore.on(GameStateStore.ITEM_REWARD_CLAIMED, onInnLogs);
  GameStateStore.on(GameStateStore.ALL_REWARDS_CLAIMED, onAllRewardsClaimed);
  InnStore.on(InnStore.GOT_INN_LOGS, onInnLogs);
  onInnLogs();
});

onBeforeUnmount(() => {
  state.modal_pixi_app?.destroy();
  CurrencyStore.removeListener(CurrencyStore.GOLD_REWARD_CLAIMED, onInnLogs);
  CurrencyStore.removeListener(CurrencyStore.PD_REWARD_CLAIMED, onInnLogs);
  GameStateStore.removeListener(GameStateStore.ITEM_REWARD_CLAIMED, onInnLogs);
  GameStateStore.removeListener(
    GameStateStore.ALL_REWARDS_CLAIMED,
    onAllRewardsClaimed
  );
  InnStore.removeListener(InnStore.GOT_INN_LOGS, onInnLogs);
});

function claimAllBtnInvisibility(log_entry) {
  const are_actual_items = Object.values(log_entry.rewards?.items ?? {}).filter(
    (x) => !!x
  ).length;
  if (
    !are_actual_items &&
    !log_entry.rewards?.gold &&
    !log_entry.rewards?.pixieDust
  ) {
    return true;
  }
  const rewards =
    log_entry.rewards[PlayerStore.getAll().loggedInPlayerId] ||
    log_entry.rewards;
  return (
    !rewards?.gold &&
    !rewards?.pixieDust &&
    !Object.values(rewards?.items ?? {}).length
  );
}

function deleteLogEntry(log_id) {
  BattleDebriefActions.deleteInnLogEntry(log_id);
}

function onInnLogs() {
  state.sorted_inn_log_entries = Object.values(InnStore.getAll().inn_logs).sort(
    (a, b) => b.timestamp - a.timestamp
  );
  nextTick(() => {
    updateModalStagePivot();
    state.rewards_key++;
  });
}

function onClaimAllClick(log_id, event) {
  event.stopPropagation();
  state.pending_claim_all[log_id] = true;
  BattleDebriefActions.claimAllRewards_Inn(log_id);
}

function updateModalStagePivot() {
  state.modal_pixi_app.stage.pivot.x = modal_canvas.value.offsetLeft;
  state.modal_pixi_app.stage.pivot.y = modal_canvas.value.offsetTop;
}

function onAllRewardsClaimed({ log_id }) {
  delete state.pending_claim_all[log_id];
}
</script>
