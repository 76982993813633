import * as PIXI from 'pixi.js';
import CanvasTools from '~/view/CanvasTools';
import Colors from '~/constants/Colors';
import BattleStore from '~/flux/stores/BattleStore';
import UnitDetailsView from './UnitDetailsView';
import ResignBattleDialog from '~/view/game-screens/battle/canvas/ResignBattleDialog';
import AbilityInfoPanel from '../info_panel/AbilityInfoPanel';
import HeroControlView from './hero_control_view/HeroControlView';
import BattleActions from '~/flux/actions/BattleActions';
import BattleCalc from 'dt-common/battle_engine/BattleCalc';

const BattleHUD = function (myWidth) {
  PIXI.Container.call(this);

  this.interactive = true;
  this.width = myWidth;
  let myHeight = (this.height = window.innerHeight * 0.26);

  let _last_unitToOrder_uid;
  let _resignBattleDialog;
  let _abilityInfoPanel;
  let _heroControlView;
  let _show_hcv_delay;
  let _activeUnitView;
  let _cancelBtn;

  this.transitionIn = () => {};

  this.transitionOut = (onComplete) => {
    onComplete();
  };

  const destroyAbilityInfoPanel = () => {
    if (_abilityInfoPanel) {
      _abilityInfoPanel.dispose();
      this.removeChild(_abilityInfoPanel);
      _abilityInfoPanel = null;
    }
  };

  const hideAbilityInfoPanel = () => {
    TweenMax.to(_abilityInfoPanel, 0.3, {
      alpha: 0,
      onComplete: destroyAbilityInfoPanel,
    });

    showHeroControlView();
  };

  const hideHeroControlView = () => {
    if (_heroControlView) {
      TweenMax.to(_heroControlView, 0.15, { alpha: 0 });
      TweenMax.to(_heroControlView.scale, 0.15, {
        x: 0,
        y: 0,
        onComplete: destroyHeroControlView,
        onCompleteParams: [_heroControlView],
      });
      _heroControlView = null;
    }
  };

  const destroyHeroControlView = (deadPanel) => {
    if (deadPanel) {
      deadPanel.dispose();
      if (deadPanel.parent) {
        deadPanel.parent.removeChild(deadPanel);
      }
    }
  };

  const destroyActiveUnitView = () => {
    if (_activeUnitView) {
      _activeUnitView.dispose();
      if (_activeUnitView.parent) {
        _activeUnitView.parent.removeChild(_activeUnitView);
      }
      _activeUnitView = null;
    }
  };

  this.dispose = () => {
    this.removeChildren();

    destroyActiveUnitView();

    if (_cancelBtn) {
      _cancelBtn.tap = _cancelBtn.click = null;
      _cancelBtn = null;
    }

    if (_resignBattleDialog) {
      _resignBattleDialog.dispose();
      _resignBattleDialog = null;
    }

    destroyAbilityInfoPanel();

    destroyHeroControlView(_heroControlView);
    _heroControlView = null;

    BattleStore.removeListener(BattleStore.ORDERS_REQUESTED, onOrdersRequested);
    BattleStore.removeListener(BattleStore.AI_TURN, onAITurn);
    BattleStore.removeListener(BattleStore.RESUMING_TIME, onResumingTime);
    BattleStore.removeListener(BattleStore.ABILITY_SELECTED, onAbilitySelected);
    BattleStore.removeListener(
      BattleStore.ORDERS_REQUESTED,
      showHeroControlView
    );
    BattleStore.removeListener(
      BattleStore.STAT_COSTS_EXACTED,
      onStatCostsExacted
    );
    BattleStore.removeListener(
      BattleStore.INSUFFICIENT_STATS,
      showHeroControlView
    );
    BattleStore.removeListener(BattleStore.ILLEGAL_ACTION, showHeroControlView);

    document.removeEventListener('keydown', onKeydown);
  };

  CanvasTools.addBackFill(this, Colors.ALMOST_BLACK);

  CanvasTools.addBorder(
    this,
    'menu_top_border.png',
    'window_border_vert.png',
    'menu_top_border.png',
    'window_border_vert.png',
    0
  );

  const makeActiveUnitView = (unit) => {
    if (!unit) {
      return;
    }

    if (_activeUnitView) {
      _activeUnitView.updateUnit(unit);
    } else {
      _activeUnitView = new UnitDetailsView(unit);
      _activeUnitView.x = Math.round(myWidth - _activeUnitView._width);
      _activeUnitView.y = Math.round(
        myHeight / 2 - _activeUnitView._height / 2
      );
      this.addChild(_activeUnitView);
    }
  };

  const onOrdersRequested = () => {
    const { battleState, unitToOrder } = BattleStore.getAll();
    const unitToOrderObj = battleState.allUnits[unitToOrder];

    if (unitToOrderObj && unitToOrderObj.uid !== _last_unitToOrder_uid) {
      _last_unitToOrder_uid = unitToOrderObj.uid;
      makeActiveUnitView(unitToOrderObj);
    }

    if (!_show_hcv_delay?._active) {
      _show_hcv_delay && _show_hcv_delay.kill();
      _show_hcv_delay = TweenMax.delayedCall(0.2, showHeroControlView);
    }
  };

  const onAITurn = () => {
    const battleStore = BattleStore.getAll();
    var actingAIUnitObj =
      battleStore.battleState.allUnits[battleStore.actingAIUnit];
    if (actingAIUnitObj) {
      makeActiveUnitView(actingAIUnitObj);
    }
  };

  const onResumingTime = () => {
    destroyActiveUnitView();
  };

  _cancelBtn = new PIXI.Sprite();
  _cancelBtn.texture = PIXI.utils.TextureCache['icon_cancel.png'];
  _cancelBtn.height = Math.min(_cancelBtn.height, myHeight * 0.25);
  _cancelBtn.scale.x = _cancelBtn.scale.y;
  _cancelBtn.x = myWidth - _cancelBtn.width * 1.5;
  _cancelBtn.y = -_cancelBtn.height * 1.4;
  this.addChild(_cancelBtn);
  _cancelBtn.interactive = _cancelBtn.buttonMode = true;
  _cancelBtn.tap = _cancelBtn.click = () => {
    if (BattleStore.getAll().selectedAbility) {
      BattleActions.selectAbility(null);
      showHeroControlView();
    } else if (_resignBattleDialog && _resignBattleDialog.parent) {
      _resignBattleDialog.transitionOut(_resignBattleDialog.dispose);
      _resignBattleDialog = null;
    } else {
      _resignBattleDialog = new ResignBattleDialog();
      _resignBattleDialog.x = Math.round(
        window.innerWidth / 2 - _resignBattleDialog.width / 2
      );
      _resignBattleDialog.y = Math.round(
        window.innerHeight / 2 - _resignBattleDialog.height / 2
      );
      _resignBattleDialog.transitionIn();
      DT_CANVAS_GLOBALS.stage.addChild(_resignBattleDialog);
    }
  };

  const onAbilitySelected = () => {
    if (_abilityInfoPanel) {
      hideAbilityInfoPanel();
    }

    var ability = BattleStore.getAll().selectedAbility;
    if (!ability) {
      if (_abilityInfoPanel) {
        destroyAbilityInfoPanel();
      }
      hideHeroControlView();
      showHeroControlView();
      return;
    }

    const { battleState, unitToOrder } = BattleStore.getAll();
    const unitToOrderObj = battleState.allUnits[unitToOrder];

    _abilityInfoPanel = new AbilityInfoPanel({
      ability,
      unit_build: unitToOrderObj,
      showCalcText: false,
    });
    _abilityInfoPanel.x = Math.round(DT_CANVAS_GLOBALS.spacing / 2);
    _abilityInfoPanel.y = Math.round(DT_CANVAS_GLOBALS.spacing / 2);
    TweenMax.from(_abilityInfoPanel, 0.4, { alpha: 0 });
    this.addChild(_abilityInfoPanel);
  };

  const showHeroControlView = () => {
    const { battleState } = BattleStore.getAll();

    if (!battleState || _heroControlView || _abilityInfoPanel) {
      return;
    }

    const unitToOrder = battleState.allPieces[BattleStore.getAll().unitToOrder];
    if (unitToOrder && unitToOrder.inPlay && !unitToOrder.dead) {
      _heroControlView = new HeroControlView(unitToOrder, hideHeroControlView);
      _heroControlView.x = myWidth * 0.242;
      _heroControlView.y = myHeight * 0.5;
      TweenMax.from(_heroControlView, 0.25, { alpha: 0, ease: Expo.easeIn });
      this.addChild(_heroControlView);
    }

    if (unitToOrder && !_activeUnitView) {
      makeActiveUnitView(unitToOrder);
    }
  };

  if (BattleStore.getAll().unitToOrder) {
    showHeroControlView();
  }

  const onStatCostsExacted = () => {
    if (_heroControlView) {
      hideHeroControlView();
    }

    const unitToOrder =
      BattleStore.getAll().battleState.allPieces[
        BattleStore.getAll().unitToOrder
      ];
    if (unitToOrder) {
      _show_hcv_delay && _show_hcv_delay.kill();
      _show_hcv_delay = TweenMax.delayedCall(
        BattleCalc.getCooldown(unitToOrder) * 0.38,
        showHeroControlView
      );
    }
  };

  BattleStore.on(BattleStore.ORDERS_REQUESTED, onOrdersRequested);
  BattleStore.on(BattleStore.AI_TURN, onAITurn);
  BattleStore.on(BattleStore.RESUMING_TIME, onResumingTime);
  BattleStore.on(BattleStore.ABILITY_SELECTED, onAbilitySelected);
  BattleStore.on(BattleStore.STAT_COSTS_EXACTED, onStatCostsExacted);
  BattleStore.on(BattleStore.INSUFFICIENT_STATS, showHeroControlView);
  BattleStore.on(BattleStore.ILLEGAL_ACTION, showHeroControlView);

  const onKeydown = (event) => {
    if (event.key === 'Escape') {
      if (_cancelBtn) {
        _cancelBtn.click();
      }
    }
  };
  document.addEventListener('keydown', onKeydown);
};
BattleHUD.prototype = Object.create(PIXI.Container.prototype);
BattleHUD.prototype.constructor = BattleHUD;
export default BattleHUD;
