import * as PIXI from 'pixi.js';
import { ObjectPool } from '~/Tools';

let _effect;
const _effectPool = new ObjectPool(
  // create func
  () => {
    _effect = new PIXI.Graphics();
    _effect.beginFill(0xffffff);
    _effect.drawRect(-1, -1, 2, 2);
    _effect.endFill();
    _effect.alpha = 0.6;
    return _effect;
  },

  // reset func
  (effect) => {
    gsap.killTweensOf(effect);
    effect.alpha = 0.6;
    effect.parent?.removeChild(effect);
  },

  // starting num
  200
);

const instances = {};

const BlessedRendering = {
  render: function (condition, gameBoard) {
    if (instances[condition.uid]) {
      return;
    }

    instances[condition.uid] = {};
    instances[condition.uid].ownerSprite =
      gameBoard.getAllPieceSprites()[condition.ownerId];
    instances[condition.uid].interval = TweenMax.delayedCall(
      0.5,
      BlessedRendering.makeEffect,
      [condition.uid]
    );
  },

  makeEffect: (conditionId) => {
    if (!instances[conditionId]) {
      return;
    }

    const ownerSprite = instances[conditionId].ownerSprite;
    if (!ownerSprite) {
      return;
    }

    var effect = _effectPool.get();
    effect.x =
      -ownerSprite.tileImg.width / 2 +
      Math.random() * ownerSprite.tileImg.width;
    effect.y = (-Math.random() * ownerSprite.tileImg.height) / 2 - 500;
    ownerSprite.addChildAt(
      effect,
      Math.floor(Math.random() * ownerSprite.children.length)
    );

    const duration = 7.0 + Math.random() * 4.0;
    console.log({ duration, put: _effectPool?.put, effect });
    TweenMax.to(effect, duration, {
      y: 0,
      alpha: 0.2,
      ease: Linear.easeNone,
      onComplete: _effectPool.put,
      onCompleteParams: [effect],
    });

    instances[conditionId].interval = TweenMax.delayedCall(
      0.2 + Math.random() * 0.5,
      BlessedRendering.makeEffect,
      [conditionId]
    );
  },

  stopRendering: function (conditionId) {
    if (instances[conditionId]) {
      if (instances[conditionId].interval) {
        instances[conditionId].interval.kill();
      }
      delete instances[conditionId];
    }
  },

  dispose: function () {
    for (var prop in instances) {
      BlessedRendering.stopRendering(prop);
    }
  },
};
export default BlessedRendering;
