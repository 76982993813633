import { EventEmitter } from 'events';

import PlayerDispatcher from '~/flux/dispatchers/PlayerDispatcher';
import PaymentsDispatcher from '~/flux/dispatchers/PaymentsDispatcher';
import { awaitSocket, registerDispatchHandlers } from '~/Tools';
import Config from '~/constants/Config';

let _socket;
let playerId;

// the stuff we serve:
let card_declined_data;
let payment_succeeded_data;
let payment_requires_action_data;
let stripe;
if (!['kongregate', 'yandex'].includes(Config.PLATFORM)) {
  async function initStripe() {
    // wait for Stripe lib to load
    await new Promise((resolve) => {
      const interval = setInterval(() => {
        if (window.Stripe) {
          clearInterval(interval);
          resolve();
        }
      }, 200);
    });

    stripe = Stripe(process.env.STRIPE_API_KEY);
  }
  initStripe();
}

const PaymentsStore = Object.assign({}, EventEmitter.prototype, {
  PAYMENT_SUCCEEDED: 'PAYMENT_SUCCEEDED',
  CARD_DECLINED: 'CARD_DECLINED',
  PAYMENT_REQUIRES_ACTION: 'PAYMENT_REQUIRES_ACTION',
  PAYMENT_ERROR: 'PAYMENT_ERROR',

  getAll() {
    return {
      payment_succeeded_data,
      card_declined_data,
      payment_requires_action_data,
    };
  },
});
export default PaymentsStore;

PlayerDispatcher.register(
  registerDispatchHandlers({
    [PlayerDispatcher.PLAYER_LOGGED_IN]: onPlayerLoggedIn,
  })
);
PaymentsDispatcher.register(
  registerDispatchHandlers({
    [PaymentsDispatcher.CREATE_PAYMENT_INTENT]: requestNewPaymentIntent,
    [PaymentsDispatcher.INIT_STEAM_PURCHASE]: requestInitSteamPurchase,
    [PaymentsDispatcher.INIT_YANDEX_PURCHASE]: initYandexPurchase,
  })
);

awaitSocket(onSocketConnected);
// awaitSocket().then(onSocketConnected);
function onSocketConnected(socket) {
  try {
    _socket = socket;

    if (!_socket.has_PaymentsStore_listeners) {
      _socket.on('card_declined', onCardDeclined);
      _socket.on('payment_requires_action', onPaymentRequiresAction);
      _socket.on('payment_succeeded', onPaymentSucceeded);
      _socket.on('payment_error', onPaymentError);
      _socket.on('steam_purchase_url', onSteamPurchaseURL);
      _socket.on('yandex_purchase_complete', onYandexPurchaseComplete);

      _socket.has_PaymentsStore_listeners = true;
    }
  } catch (err) {
    logError(err, {
      module: 'PlayerStore',
      func: 'onSocketConnected',
    });
  }
}

async function onPlayerLoggedIn(action) {
  try {
    playerId = action.player._id;

    // Yandex: consume any unprocessed purchases
    if (Config.PLATFORM === 'yandex') {
      const payments = await ysdk.getPayments({ signed: true });
      const purchases = await payments.getPurchases();
      for (let i = 0; i < purchases.length; ++i) {
        setTimeout(processYandexPurchase, i * 1200, [
          purchases[i].purchaseData,
        ]); // throttle to get past redlock
      }
    }
  } catch (err) {
    logError(err, {
      module: 'PaymentsStore',
      func: 'onPlayerLoggedIn',
      action,
    });
  }
}

function requestInitSteamPurchase(action) {
  const { purchase_selection } = action;
  _socket.emit('init_steam_purchase', { playerId, purchase_selection });
}

function requestNewPaymentIntent(action) {
  const { purchase_selection, payment_method_id } = action;
  _socket.emit('create_stripe_payment_intent', {
    playerId,
    purchase_selection,
    payment_method_id,
  });
}

function onCardDeclined(data) {
  try {
    if (data.playerId !== playerId) {
      throw new Error('got card_declined msg for wrong playerId');
    }

    card_declined_data = data;
    PaymentsStore.emit(PaymentsStore.CARD_DECLINED, data);
  } catch (err) {
    logError(err, {
      module: 'PaymentsStore',
      func: 'onCardDeclined',
      data,
    });
  }
}

function onPaymentRequiresAction(data) {
  try {
    if (data.playerId !== playerId) {
      throw new Error('got payment_requires_action msg for wrong playerId');
    }

    payment_requires_action_data = data;
    PaymentsStore.emit(PaymentsStore.PAYMENT_REQUIRES_ACTION, data);
  } catch (err) {
    logError(err, {
      module: 'PaymentsStore',
      func: 'onPaymentRequiresAction',
      data,
    });
  }
}

function onPaymentSucceeded(data) {
  try {
    if (data.playerId !== playerId) {
      throw new Error('got payment_succeeded msg for wrong playerId');
    }

    payment_succeeded_data = data;
    PaymentsStore.emit(PaymentsStore.PAYMENT_SUCCEEDED, data);
  } catch (err) {
    logError(err, {
      module: 'PaymentsStore',
      func: 'onPaymentSucceeded',
      data,
    });
  }
}
function onPaymentError(data) {
  try {
    if (data.playerId !== playerId) {
      throw new Error('got payment_error msg for wrong playerId');
    }

    PaymentsStore.emit(PaymentsStore.PAYMENT_ERROR);
  } catch (err) {
    logError(err, {
      module: 'PaymentsStore',
      func: 'onPaymentSucceeded',
      data,
    });
  }
}

function onSteamPurchaseURL(params) {
  const newWindow = window.open(
    params.steamurl +
      `?returnurl=${encodeURI('https://dungeonteam.com?platform=steam_purchase_redirect')}`,
    'Buy Pixie Dust via Steam',
    'width=800,height=600'
  );

  // Check if the popup is closed
  const intervalId = setInterval(() => {
    if (newWindow.closed) {
      clearInterval(intervalId);
      _socket.emit('steam_purchase_popup_closed', { playerId, ...params });
    }
  }, 100);
}

async function initYandexPurchase(action) {
  const { purchase_selection } = action;
  try {
    const payments = await ysdk.getPayments({ signed: true });
    const purchase = await payments.purchase({
      id: purchase_selection.id,
    });
    processYandexPurchase(purchase);
  } catch (err) {
    logError(err, {
      module: 'PaymentsStore',
      func: 'initYandexPurchase',
      purchase_selection,
    });
  }
}

function processYandexPurchase(purchase) {
  try {
    if (Array.isArray(purchase)) {
      [purchase] = purchase;
    }

    _socket.emit('process_yandex_purchase', {
      playerId,
      purchase,
    });
  } catch (err) {
    logError(err, { purchase });
  }
}

async function onYandexPurchaseComplete(purchase) {
  try {
    const payments = await ysdk.getPayments();
    payments.consumePurchase(purchase.purchaseToken);
  } catch (err) {
    logError(err, { purchase });
  }
}
